var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between align-items-center dm-sans-font border-b min-w-[600px]"},[_c('div',{staticClass:"col-8 flex justify-between align-items-er row"},[_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":{
					name: 'single-product',
					params: {
						company_id: _vm.product?.company,
						product_id: _vm.product?.id,
					},
				},"target":"_blank"}},[(_vm.product.images.length > 0)?_c('img',{staticClass:"w-24",attrs:{"src":_vm.product.images[0].image,"alt":""}}):_vm._e()])],1),_c('div',{staticClass:"flex flex-col pl-1 col-6"},[_c('p',{staticClass:"cp-desc font-semibold"},[_vm._v(_vm._s(_vm.product.article_number))]),_c('span',{staticClass:"cp-desc",staticStyle:{"font-size":"13px !important"}},[_vm._v(_vm._s(_vm.product.description))]),_c('br'),_c('span',{staticClass:"cp-price"},[_vm._v(_vm._s(_vm.calculatePrice(_vm.product))+" x "+_vm._s(_vm.product.qty))]),(
					_vm.product?.has_discount &&
					Number(_vm.product?.discount_value) > 0
				)?_c('span',{staticClass:"font-black !text-red-500 text-[.8rem] font-bold"},[_vm._v(_vm._s(_vm.$t("discounted"))+" "+_vm._s(_vm.convertDiscountValueToPercentage( _vm.product?.discount_value, _vm.product?.discount_type, _vm.product?.sales_price ))+" %")]):_vm._e(),_c('br'),(
					_vm.product.allow_custom_row_text === true &&
					_vm.product.hasOwnProperty('extra_item')
				)?_c('small',{staticClass:"cp-price",staticStyle:{"font-size":"12px !important","color":"#2631bf !important"}},_vm._l((_vm.product.extra_item),function(item,key){return (_vm.product.hasOwnProperty('extra_item'))?_c('p',{key:key,staticClass:"text-xs font-semibold",staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.item)+" ")]):_vm._e()}),0):_vm._e()])]),_c('div',{staticClass:"col-4 flex space-x-2"},[_c('div',{staticClass:"flex gap-4 w-full items-center"},[_c('p',{staticClass:"!text-[1rem] !text-blue-900 font-bold"},[_vm._v(" "+_vm._s(_vm.calculatePrice(_vm.product))+" "+_vm._s(_vm.product.currency)+" ")]),_c('input-number',{attrs:{"num":_vm.num},on:{"onchange":_vm.handleChange}})],1),_c('div',[_c('span',{staticClass:"cursor-pointer"},[_c('span',{staticClass:"flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer",on:{"click":function($event){return _vm.RemoveFromCart(_vm.product)}}},[_c('img',{attrs:{"src":require("./../../../assets/images/icons/delete.png"),"alt":"delete"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }